import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 style={{ marginBottom: `1.45rem` }}>Hi, My name is Joel Kurian.</h1>
    <h2>Soon, there be dragons here...</h2>
  </Layout>
)

export default IndexPage
